<template>
  <div>
    <article>
      <h3 class="center">{{ appName }}个人信息保护政策</h3>
      <p style="text-align: right">公布时间：2023年【06】月【05】日</p>
      <p style="text-align: right">生效时间：2023年【06】月【05】日</p>
      <p style="text-align: right">版本：第【一】版</p>
        <p class="first-line" type="p"> <p>鉴于：</p></p>
        <p class="primary-line" type="p-content">
          <p>{{ appName }}（以下简称本平台）非常重视用户的隐私和个人信息保护。</p>
          <p
            >本平台希望通过本 <a @click="openPage(1)" class="service">《用户协议》</a>与
            <a @click="openPage(2)" class="service">《隐私政策》</a
            >向您说明在您使用本平台的服务时，本平台如何收集、使用、储存、和分享这些信息，以及本平台为您提供的访问、更新、控制和保护这些信息的方式。本<a
              class="service"
              @click="openPage(1)"
              >《用户协议》</a
            >与
            <a @click="openPage(2)" class="service">《隐私政策》</a
            >与您所使用的本平台服务息息相关，本平台希望您能够仔细阅读，并在需要时，按照本<a
              class="service"
              @click="openPage(1)"
              >《用户协议》</a
            >与 <a @click="openPage(2)" class="service">《隐私政策》</a>的指引，作出您认为适当的选择。本<a
              class="service"
              @click="openPage(1)"
              >《用户协议》</a
            >与
            <a @click="openPage(2)" class="service">《隐私政策》</a
            >之中涉及的相关技术词汇，本平台尽量以简明扼要表述向您解释，以便您的理解。</p
          >
          <p>第一条、定义</p>
          <p>
            1.1用户或您：指“{{ appName }}”平台的注册用户；若“{{ appName }}”平台产品或服务的实际使用人为注册用户的被监护人，则该等实际使用人也属于本协议项下的“您”“用户”。
          </p>
          <p>
            1.2“{{ appName }}”平台：指成都开心音符科技有限公司及其关联方开发、运营的互联网音乐平台，包括APP客户端、公众号、小程序等。
          </p>
          <p> 1.3开心音符公司：指{{ appName }}产品和/或服务的运营方成都开心音符科技有限公司及其关联公司。 </p>
          <p>
            1.4关联公司：指相互之间存在关联关系，关联关系是指开心音符科技有限公司控股股东、实际控制人、董事、监事、高级管理人员与其直接或者间接控制的企业之间的关系，以及可能导致“{{ appName }}”平台利益转移的其他关系。
          </p>
          <p>
            1.5{{ appName }}产品和/或服务：指成都开心音符科技有限公司及其关联公司通过“{{ appName }}”平台向您提供的产品或服务，内容包括但不限于搜索、试听、观看、播放、缓存、下载及管理音频或视频作品等基础服务，收藏、关注、评论、分享、发布信息内容、直播、K歌、手机铃声优化等交互服务，以及充值、打赏等增值服务。
          </p>
          <p>第二条、用户个人信息保护</p>
          <p> 2.1{{ appName }}与您一同致力于您（即能够独立或与其他信息结合后识别您身份的信息）的保护。 </p>
          <p>
            2.2保护用户个人信息是{{ appName }}的基本原则之一，在使用{{ appName }}产品和/或服务的过程中，您可能需要提供您的个人信息（包括但不限于您的姓名、电话号码、位置信息、设备信息等），以便开心音符公司向您提供更好的服务和相应的技术支持。开心音符公司将依法保护您浏览、修改、删除相关个人信息以及撤回授权的权利，并将运用加密技术、匿名化处理等其他与{{ appName }}产品和/或服务相匹配的技术措施及其他安全措施保护您的个人信息。
          </p>
          <p>
            2.3更多关于您个人信息保护的内容，请参看
            <a @click="openPage(2)" class="service">《{{ appName }}个人信息保护政策》</a>
            及
            <a @click="openPage(3)" class="service">《{{ appName }}儿童个人信息保护政策》</a>
            。
          </p>
          <p> 第三条、我们如何收集和使用您的个人信息 </p>
          <p> （一）当您使用我们的服务时，我们需要收集和使用您如下个人信息： </p>
          <p>
            1.用户注册。您通过第三方账号（QQ账号、微信账号或苹果账号）授权共享账号信息并申请注册成为我们的会员时，我们会收集您的头像、昵称、性别。>若您拒绝向我们提供前述信息，将无法注册用户账号。
          </p>
          <p>
            2.现金提现。“{{ appName }}”平台面向用户推出了一系列的现金奖励活动。当用户在使用“{{ appName }}”平台进行音视频作品分享、邀请好友使用“{{ appName }}”平台、关注{{ appName }}官方微信公众号等操作时，将依据“{{ appName }}”平台届时实施的活动规则获得现金奖励，还可通过参与喝水、步数、观看视频、偷步数、浇水、下载第三方APP等方式赚取金币，可将获得的金币兑换成现金。当您选择通过“{{ appName }}”平台（IOS版需要通过“{{ appName }}娱乐平台”微信公众号进行操作）将现金提现至微信支付账号时，开心音符公司合作的林州薪云信息科技有限公司出于账户安全管理以及提现功能实现之必要将收集接收提现资金的微信账户所绑定的手机号码及对应的真实姓名；当您选择通过“{{ appName }}”平台将现金提现至支付宝账号时，开心音符公司合作的林州薪云信息科技有限公司出于账户安全管理以及提现功能实现之必要将收集接收提现资金的支付号账号及对应的真实姓名。若您拒绝提供前述信息，将无法使用现金提现功能。
          </p>
          <p>
            3.个性化推荐。为了便于向您提供符合您个性偏好的音频、视频作品等内容，我们需要收集您的点击次数、关注对象、收藏内容、搜索内容、浏览内容、分享内容、评论内容、点赞（喜欢）发布内容及次数、反馈等浏览使用行为、浏览时长等信息进行个性化推荐。若您不需要使用我们的个性化推荐服务，可以通过【设置】-【个性化推荐】关闭个性化推荐功能，但该功能关闭后，我们仍可能向用户推荐不针对其偏好的内容。
          </p>
          <p>
            4.产品服务提供及安全保障。为了保障您使用我们产品与服务的安全性，识别账号异常状态、了解产品适配性、提供浏览、搜索等基本服务，使我们更加了解您如何接入和使用我们的服务，从而针对性地回应您的个性化需求，更好地预防欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等网络与信息安全风险，保护您、其他用户或公众人身财产安全免受侵害，我们需要采集您所使用设备的信息和日志信息。包括：设备品牌及型号、设备屏幕规格、操作系统版本、屏幕分辨率、浏览器类型、电信运营商、使用语言、硬件序列号、IP地址、MEID、Android
            ID、蓝牙地址、WLAN接入点（BSSID、SSID）、OAID、IMSI、ICCID、IDFA、IDFV、GAID、OpenUDID用户使用终端设备访问本平台时的设备传感器数据、访问日期和时间信息、服务日志。
          </p>
          <p> 第四条、我们如何使用Cookie和同类技术 </p>
          <p>
            Cookie和设备信息标识等同类技术是互联网中普遍使用的技术。我们可能通过Cookies或同类技术收集您的设备型号、操作系统、唯一设备标识符、登录IP地址信息、以及缓存您的浏览信息、点击信息。我们向您发送Cookies是为了保障产品与服务的安全、高效运转，我们可能会设置认证与保障安全性的Cookie或匿名标识符，使我们确认你是否安全登录服务，或者是否遇到盗用、欺诈及其他不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。此外，还可以简化您重复登录的步骤实现登录时无需重复输入用户名及密码信息，并帮助判断您的登录状态以及账户或数据安全。我们承诺，不会将Cookies用于本政策所述目的之外的任何用途。
          </p>
          <p> 第五条、我们如何存储及保护您的个人信息 </p>
          <p>
            我们会采取加密等技术措施存储您个人信息，确保信息安全。我们仅在本政策所述目的所必须期间和法律法规及监管规定的时间限内存储您的个人信息。我们依照法律法规的规定，将您的个人信息存储于中华人民共和国境内。除法律或相关法规另有约定外，开心音符公司在为提供服务之目的所必需的期间内保留您的个人信息，但您要求我们立即删除或注销账户、或法律法规另有规定的除外。
          </p>
          <p>
            如果我们终止服务或运营，我们将及时停止继续收集您个人信息的活动，同时会遵守相关法律法规要求提前向您通知，并终止服务或运营后对您的个人信息进行删除或匿名化处理，法律法规或监管部门另有规定的除外。
          </p>
          <p> 第六条、您的权利 </p>
          <p>
            无论您何时使用我们的相关产品和/或服务，我们都会尽一切可能保证您可以顺利访问自己的账户信息。如果这些信息有误，我们会努力提供各种方式来让您快速更新或删除账户内信息。为了使您拥有充分的能力保障隐私和安全，您拥有如下权利：
          </p>
          <p> 1. 访问权 </p>
          <p>
            您有权访问存储在“{{ appName }}”平台中您的个人信息。当您通过账号登录{{ appName }}APP后，在“个人中心”功能页面下访问您的个人信息。
          </p>
          <p> 2. 更正权 </p>
          <p>
            您有权直接通过“{{ appName }}”平台APP更正您的个人信息。若您无法操作更正的，可以通过本政策所载的客服联系方式与我们联系，要求我们对错误或不完整的信息做出更正或补充。
          </p>
          <p> 3. 撤销权 </p>
          <p> 改变您授权同意的范围或撤回授权，我们将通过以下方式保障您撤回同意的权利： </p>
          <p>
            （1）您可以通过设备的设置功能，关闭相应设备权限（包括位置、通讯录、照片、麦克风、相机、通知等），撤回对{{ appName }}APP获取您设备权限的授权，用户可在“手机设置→权限”中关闭相关隐私授权。
          </p>
          <p> 4. 删除权 </p>
          <p> 在以下情形中，您可以向我们提出删除个人信息的请求： </p>
          <p> （1）处理目的已实现、无法实现或者为实现处理目的不再必要； </p>
          <p> （2）我们停止提供产品或者服务，或者保存期限已届满； </p>
          <p> (3）您撤回处理个人信息的同意； </p>
          <p> （4）我们违反法律、行政法规或者违反与您的约定处理个人信息； </p>
          <p> （5）法律、行政法规规定的其他情形。 </p>
          <p>
            以上删除请求一旦被响应，除法律法规另有规定要求保留的信息外（如流水记录等），您的个人信息将被及时删除或匿名化处理。当您在我们的某项及/或全部服务项下的个人信息被删除后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时及时删除这些信息。在满足本政策、用户协议要求及法律法规要求的最短保存期限的情况下，我们将对您的个人信息进行删除或匿名化处理，但这样可能导致我们无法向您提供相应的服务。
          </p>
          <p> 5. 注销权 </p>
          <p> 除如下情形外，您可通过{{ appName }}APP下的“设置→注销账号”所载的方式，联系我们注销您的注册账户： </p>
          <p>
            （1）为配合人民检察院、公安机关、国家安全机关侦查用户使用“{{ appName }}平台”过程中产生的犯罪行为，更好保护其他用户生命财产安全，为配合人民法院查清案情，您的个人信息和账户将被保存。
          </p>
          <p> 2）为遵守法律法规、政策等关于个人信息的保存期限的规定。 </p>
          <p>
            请您注意，在您申请注销账户后，我们将在法律规定的时间进行审核处理。若注销账号申请通过审核，原账号下的所有用户数据和账户信息且不可恢复；但在特定情形下，如合理必要地履行我们的法律义务、解决争议、防止欺诈与活动福利滥用等情形，我们将在使用者账号注销后保留不可直接识别您的个人信息。
          </p>
          <p> 6. 获取您的个人信息副本 </p>
          <p>
            我们将根据您的书面请求，为您提供以下类型的个人信息副本：您的个人基本信息、身份信息。您可以随时联系我们的客服人员，我们将在十五天内回复您的请求。但请注意，我们为您提供的信息副本仅以我们收集的信息为限。
          </p>
          <p> 7. 约束信息系统自动决策 </p>
          <p>
            在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。
          </p>
          <p> 8. 转移个人信息 </p>
          <p>
            若您请求将您的个人信息转移至您指定的个人信息处理者，符合国家网信部门规定条件的，我们将为您提供转移的途径。
          </p>
          <p> 9. 响应您的上述请求 </p>
          <p>
            （1）如果您无法通过上述方式访问、更正或删除您的个人信息，或您需要访问、更正或删除您在使用我们的产品和/或服务时所产生的其他个人信息，或您认为我们存在任何违反法律法规或与关于个人信息的收集或使用的约定，您均可通过本政策所载的客服联系方式与我们取得联系。
          </p>
          <p>
            （2）保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证您的身份，然后再处理您的要求。我们将在十五个工作日内做出答复。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段(需要开发新系统或从根本上改变现行惯例)、给他人合法权益带来风险或者非常不切实际(例如：涉及备份磁带上存放的信息)的请求，我们可能会予以拒绝。
          </p>
          <p> 第七条、其他 </p>
          <p>
            7.1本协议的成立、生效、履行、解释及争议的解决均应适用中华人民共和国法律。倘若本协议之任何规定因与中华人民共和国法律抵触而无效，则这些条款应在不违反法律的前提下按照尽可能接近本协议原条文目的之原则进行重新解释和适用，且本协议其它规定仍应具有完整的效力及效果。
          </p>
          <p>
            7.2若您与开心音符公司发生任何争议，双方应尽量友好协商解决，协商不成，您同意应将争议提交至开心音符公司住所地人民法院诉讼解决。
          </p>
          <p> 13.3本协议中的标题仅为方便阅读而设，并不影响本协议中任何规定的含义或解释。 </p>
          <p>
            13.4您和开心音符公司均是独立的主体，在任何情况下本协议不构成开心音符公司对您的任何形式的明示或暗示担保或条件，双方之间亦不构成代理、合伙、合营或雇佣关系。
          </p>
        </p>
    </article>
  </div>
</template>

<script>
  export default {
    name: 'PrivacyProtocol',
    props: {},
    data() {
      return {
        appName: '',
        appNameMap: new Map([
          ['kuaiyin', { appName: '快音' }],
          ['kuaiyinyue', { appName: '快音悦' }],
        ]),
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      let app_name = 'kuaiyinyue';

      if (this.$route.query.app_name) {
        app_name = this.$route.query.app_name;
      }

      this.appName = this.appNameMap.get(app_name).appName;
      document.title = `${this.appName}个人信息保护政策`;
    },
    methods: {
      openPage(type) {
        console.log(`openPage`, type);
      }
    },
  };
</script>

<style scoped>
  body {
    font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
      sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    /*padding-left: constant(safe-area-inset-left);*/
    /*padding-left: env(safe-area-inset-left);*/
    /*padding-right: constant(safe-area-inset-right);*/
    /*padding-right: env(safe-area-inset-right);*/
    /*padding-bottom: constant(safe-area-inset-bottom);*/
    /*padding-bottom: env(safe-area-inset-bottom);*/
  }

  article {
    margin: 0 4%;
  }

  h3 {
    margin: 15px 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #3c3c3c;
    font-size: 15px;
  }
  h5 {
    margin: 15px 0;
    font-size: 14px;
  }
  p {
    margin: 12px 0;
    font-size: 13px;
  }
  .bold {
    font-weight: bold;
  }
  .center {
    text-align: center;
  }
  ul > li {
    margin: 5px 0;
    font-size: 13px;
    list-style: none;
  }
  ul > li > span {
    display: inline-block;
    margin: 4px 0;
  }
  i {
    font-style: italic;
  }

  .table {
    margin: 15px 0;
    border: 1px solid #aaa;
    width: 100%;
    border-bottom: 0;
  }
  .t-head {
    background: #c6c6c6 no-repeat scroll 0 0;
  }
  .t-head ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .t-head ul li {
    width: 20%;
    text-align: center;
  }
  .t-body {
    background-color: #fff;
  }
  .t-body ul {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #aaaaaa;
  }
  .t-body ul li {
    position: relative;
    width: 20%;
    padding: 4px;
    text-align: center;
    word-break: break-all;
  }
  a {
    /* color: #007aff; */
    /* text-decoration: underline; */
    color: #000000;
  }
</style>
